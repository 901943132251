.logo{
    padding-right: 20px;
    height: 114px;
    border-right: solid 1px #c8c8c8;
    padding-top: 32px;
  }
  .logo img{
    width: 140px;
    height: 48px;
  }
  .logoText{
    font-family: 'LatoLatin';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #df6951;
    margin-left: 20px;
  }
  .logoContainer{
    text-align: left;
    position: relative;
  }

  .logoContainer div{
    display: inline-block;
    text-align: center
  }

  .container {
      width: 710px;
      position:relative;
      justify-content:center;
      align-items: center;
      margin-left: -62px;
  }

  .lgContainer {
    width: 100%;
    max-width: 990px;
    align-content: center;
    position:relative;
    margin: auto;
  }

  .fullWidth {
      width: 100%;
  }

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    float: right;
    height: 114px;
    padding-right:0px
}
.copyrightText{
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.formContainer {
    float: right;
    width: 551px;
}

.row{
    margin-top: 48.5px;
}

.alignCenter {
    justify-content:center;
    align-items: center;
}

@media (max-width: 1020px){
  .lgContainer{
    width: 100%;
    max-width: 900px;
  }
}
@media (max-width: 992px){
  .lgContainer .search-button{
    width: 100%;
  }
}
@media (max-width: 768px){
  .container{
    margin-left: 0;
    width: 100%;
  }
}
@media (max-width: 580px){
  .formContainer{
    width: 100%;
    margin-top: 10px;
  }
  .buttonContainer{
    display: block;
    flex-basis: auto;
    padding-left: 0;
    padding-top: 10px;
  }
  .logo{
    border: none;
  }
}