.navbar-toggler {
    margin-left: 15px;
  }

  .navbar-header{
    background-color: white;
    width:100%;
    margin: 0 auto;
    min-height: 67px;
    flex: center;
    align-items: center;
    display:flex;
  }

  .side-menu-container {
    margin-top: 33px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 33px;
  }
  .navbar.fixed-left{
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:1;
    box-shadow: 0 0px 5px rgba(39, 39, 39, 0.3);
    padding-right: 0;
    padding-left: 0;
    background-color: #f4f5f7;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .collapse-container{
    overflow-y: auto;
  }

  @media (min-width:768px){
    .navbar.fixed-left{
        bottom:0;
        width:270px;
        flex-flow:column nowrap;
        align-items:flex-start;
        height: 100%;
    }
    .navbar.fixed-left .navbar-collapse{
        flex-grow:0;
        flex-direction:column;
        width:100%;
        overflow: auto;
    }
    .navbar.fixed-left .navbar-collapse .navbar-nav{
        flex-direction:column;
        width:100%;
    }
    .navbar.fixed-left .navbar-collapse .navbar-nav .nav-item{
        width:100%
    }
    .navbar.fixed-left .navbar-collapse .navbar-nav .nav-item .dropdown-menu{
        top:0
    }
  }
  @media (min-width:768px){
    .navbar.fixed-left{
        right:auto
    }
    .navbar.fixed-left .navbar-nav .nav-item .dropdown-toggle:after{
        border-top:.3em solid transparent;
        border-left:.3em solid;
        border-bottom:.3em solid transparent;
        border-right:none;
        vertical-align:baseline
    }
    .navbar.fixed-left .navbar-nav .nav-item .dropdown-menu{
        left:100%
    }
  }
